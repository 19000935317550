@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
body {
  padding-top: 70px;
  font-family: 'Open Sans', sans-serif; }

.navbar {
  margin-bottom: 20px; }

.jumbotron {
  text-align: center;
  background: transparent; }
  .jumbotron p {
    font-size: 14px; }

.btn-play {
  padding: 12px 6px; }

.entry-box {
  height: 100px;
  font-size: 80px;
  padding: 0;
  text-align: center;
  font-family: Consolas,"Liberation Mono",Courier,monospace;
  color: #000; }

.form-control.is-invalid {
  padding-right: 0;
  padding-right: initial;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }

.user-img {
  display: block;
  margin: auto;
  max-height: 400px; }

.cancel-dispatch:disabled,
.cancel-dispatch[disabled] {
  background-color: #cccccc !important;
  color: #666666 !important;
  border: none !important;
  cursor: not-allowed !important; }

.swal-footer {
  text-align: unset !important; }

@media (max-width: 440px) {
  img#provider_logo {
    order: 1;
    margin: 10px 0 !important;
    display: flex !important;
    width: 60px;
    object-fit: contain; }
  .navbar-collapse {
    order: 1; } }

.cancel-dispatch:disabled,
.cancel-dispatch[disabled] {
  background-color: #cccccc !important;
  color: #666666 !important;
  border: none !important;
  cursor: not-allowed !important; }

.swal-footer {
  text-align: unset !important; }

